import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../components/Layout'
import HeaderSEO from '../components/partials/HeaderSEO'
import AboveFooterSection from '../components/AboveFooterSection'

import { StaticImage } from 'gatsby-plugin-image'

const architecture = () => {
  const data = useStaticQuery(graphql`
    query ArchitectureSEO {
      allContentfulPages(
        filter: {
          pageReference: { pageIdentifier: { eq: "Product_Architecture" } }
        }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <section className="pt-5 pb-5 mt-5">
        <div className="container-1 ipad-margin-top">
          <div className="row mt-3">
            <div className="col-md-6 pt-0 col-12">
              <p className="title breadcrumb-text pt-4">
                <span className="title_text">PRODUCT</span> /
                <span className="title_text font-weight-bold text-uppercase">
                  Architecture
                </span>
              </p>
              <h1>Architecture & Integrations</h1>
              <div className="pr-2">
                <p className="hero-description-dark pr-5">
                  Packed with enterprise integrations to fit your existing
                  systems yet so lightweight that it runs on only 4GB
                </p>
              </div>
              <div className="roll-button mt-2">
                <a className="bg-red text-white " href="/downloads/lenses/">
                  <span data-hover="Download now">Download now</span>
                </a>
              </div>
              <div className="mt-4">
                <a
                  className="primary-text"
                  href="/product-sheets/lenses-reference-architecture/"
                >
                  Reference architecture pdf{' '}
                  <i className="red-pointer-right"></i>
                </a>
              </div>
            </div>
            <div className="col-md-6 pt-0 col-12 ipad-margin-top mobile-margin-top">
              <StaticImage
                className="img-fluid mx-auto d-block w-75"
                src="../resources/images/vectors/undraw_server_q2pb.svg"
                placeholder="tracedSVG"
                alt="Lenses architecture & integrations"
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-1 ">
          <div className="row  mt-5 ">
            <div className="col-md-8 mt-5 ">
              <h2 className="paragraph-title pb-1">Lenses WEB / CLI / API</h2>
              <p>
                Bring streaming to everyone via a rich and{' '}
                <a href="/usecases/apache-kafka-security/">secure web UI</a>.
                Go, Python clients and other APIs to automate and integrate with
                your CI/CD.{' '}
              </p>
            </div>

            <div className="col-md-8 col-12 mt-5">
              <h2 className="paragraph-title pb-1">Compatibility</h2>
              <p>
                Works with all Kafka distributions including Apache Kafka,
                Confluent, Cloudera, Amazon MSK, Azure and other managed
                services
              </p>
            </div>

            <div className="col-md-8 col-12 mt-5">
              <h2 className="paragraph-title pb-1">Lenses SQL</h2>
              <p>
                No need to re-skill:{' '}
                <a href="https://docs.lenses.io/4.3/sql/">
                  use the language of data
                </a>{' '}
                to explore your Kafka streams, configure data integrations and
                even build stream processing apps
              </p>
              <a href="/product/sql/" className=" primary-text">
                Lenses SQL <i className="red-pointer-right"></i>
              </a>
            </div>

            <div className="col-md-8 col-12 mt-5">
              <h2 className="paragraph-title pb-1">Streaming Flows</h2>
              <p>
                Build and deploy streaming flows in minutes and have them scaled
                via your existing data infrastructure such as{' '}
                <a href="https://docs.lenses.io/4.3/installation/kubernetes/">
                  Kubernetes
                </a>
              </p>
              <a href="/usecases/kafka-streams/" className=" primary-text">
                Kafka streams <i className="red-pointer-right"></i>
              </a>
            </div>

            <div className="col-md-8 col-12 mt-5">
              <h2 className="card-title paragraph-title pb-0">Connectors</h2>
              <p className="card-text">
                Access the biggest open source library of connectors to get data
                in and out of Apache Kafka.{' '}
                <a href="https://docs.lenses.io/4.3/integrations/connectors/">
                  Lenses connectors
                </a>{' '}
                also provide SQL syntax to simplify configuration
              </p>
              <a href="/kafka-connectors/" className=" primary-text">
                Lenses Connectors <i className="red-pointer-right"></i>
              </a>

              <div className="row mt-4 align-items-center">
                <div className="col-md-3 offset-sm-0 offset-3">
                  <a
                    href="/kafka-connectors/jms-to-kafka/"
                    className="logo-box-link"
                    id="connect-jms"
                  >
                    <div className="logo-box d-flex align-items-center">
                      <div className="col mobile-padding-reset">
                        <StaticImage
                          className="img-fluid"
                          src="../resources/images/logos-connect/jms.png"
                          placeholder="tracedSVG"
                          alt="jms to kafka connector"
                        />
                      </div>
                      <div className="col mobile-padding-reset">JMS</div>
                    </div>
                  </a>
                </div>
                <div className="col-md-3 offset-sm-0 offset-3">
                  <a
                    href="/kafka-connectors/kafka-to-elastic6/"
                    className="logo-box-link"
                    id="connect-elastic"
                  >
                    <div className="logo-box d-flex align-items-center">
                      <div className="col mobile-padding-reset">
                        <StaticImage
                          className="img-fluid"
                          src="../resources/images/logos-connect/elastic.png"
                          placeholder="tracedSVG"
                          alt="kafka to elastic6 connector"
                        />{' '}
                      </div>
                      <div className="col mobile-padding-reset">Elastic</div>
                    </div>
                  </a>
                </div>
                <div className="col-md-3 offset-sm-0 offset-3">
                  <a
                    href="/kafka-connectors/kafka-to-redis/"
                    className="logo-box-link"
                    id="connect-redis"
                  >
                    <div className="logo-box d-flex align-items-center">
                      <div className="col  mobile-padding-reset">
                        <StaticImage
                          className="img-fluid "
                          src="../resources/images/logos-connect/redis.png"
                          placeholder="tracedSVG"
                          alt="kafka to redis connector"
                        />{' '}
                      </div>
                      <div className="col  mobile-padding-reset">Redis</div>
                    </div>
                  </a>
                </div>
                <div className="col-md-3 offset-sm-0 offset-3">
                  <a
                    href="/kafka-connectors/mqtt-to-kafka/"
                    className="logo-box-link"
                    id="connect-mqtt"
                  >
                    <div className="logo-box d-flex align-items-center">
                      <div className="col  mobile-padding-reset">
                        <StaticImage
                          className="img-fluid "
                          placeholder="tracedSVG"
                          src="../resources/images/logos-connect/mqtt.png"
                          alt="mqtt to kafka connector"
                        />
                      </div>
                      <div className="col  mobile-padding-reset">MQTT</div>
                    </div>
                  </a>
                </div>
                <div className="col-md-3 offset-sm-0 offset-3">
                  <a
                    href="/kafka-connectors/kafka-to-influxdb/"
                    className="logo-box-link"
                    id="connect-influx"
                  >
                    <div className="logo-box d-flex align-items-center">
                      <div className="col-6 mobile-padding-reset">
                        <StaticImage
                          className="img-fluid"
                          src="../resources/images/logos-connect/influxdb.jpg"
                          placeholder="tracedSVG"
                          alt="kafka to influxdb connector"
                        />
                      </div>
                      <div className="mobile-padding-reset">InfluxDB</div>
                    </div>
                  </a>
                </div>
                <div className="col-md-3 offset-sm-0 offset-3">
                  <a
                    href="/kafka-connectors/kafka-to-cassandra/"
                    className="logo-box-link"
                    id="connect-cassandra"
                  >
                    <div className="logo-box d-flex align-items-center">
                      <div className="col-5  mobile-padding-reset">
                        <StaticImage
                          className="img-fluid"
                          src="../resources/images/logos-connect/cassandra.jpg"
                          placeholder="tracedSVG"
                          alt="kafka to cassandra connector"
                        />
                      </div>
                      <div className="mobile-padding-reset">Cassandra</div>
                    </div>
                  </a>
                </div>
                <div className="col-md-3 offset-sm-0 offset-3">
                  <a className="primary-text pl-3" href="/kafka-connectors/">
                    + More
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-8 mt-5">
              <h2 className="card-title paragraph-title pb-0">
                Monitoring, Audits and Alerting
              </h2>
              <p className="card-text">
                Integrate metrics, events, alerts and audits to your monitoring,
                alerting and log management solutions
              </p>

              <a href="/usecases/kafka-monitoring/" className=" primary-text">
                Kafka monitoring integrations{' '}
                <i className="red-pointer-right"></i>
              </a>

              <div className="row mt-4 ">
                <div className="col-md-4 offset-sm-0 offset-2">
                  <div className="logo-box logo-box-width d-flex align-items-center">
                    <div className="col mobile-padding-reset ">
                      <StaticImage
                        className="img-fluid"
                        src="../resources/images/logos-integrations/prometheus.png"
                        placeholder="tracedSVG"
                        alt="prometheus monitoring"
                      />
                    </div>
                    <div className="col mobile-padding-reset">Prometheus</div>
                  </div>
                </div>
                <div className="col-md-4 offset-sm-0 offset-2">
                  <div className="logo-box logo-box-width d-flex align-items-center">
                    <div className="col mobile-padding-reset">
                      <StaticImage
                        className="img-fluid"
                        src="../resources/images/logos-integrations/grafana.png"
                        placeholder="tracedSVG"
                        alt="grafana monitoring"
                      />
                    </div>
                    <div className="col mobile-padding-reset">Grafana</div>
                  </div>
                </div>
                <div className="col-md-4 offset-sm-0 offset-2">
                  <div className="logo-box logo-box-width d-flex align-items-center">
                    <div className="col mobile-padding-reset">
                      <StaticImage
                        className="img-fluid "
                        src="../resources/images/logos-integrations/alertmanager.png"
                        placeholder="tracedSVG"
                        alt="alert management"
                      />
                    </div>
                    <div className="col mobile-padding-reset">
                      Alert Manager
                    </div>
                  </div>
                </div>
                <div className="col-md-4 offset-sm-0 offset-2">
                  <div className="logo-box logo-box-width d-flex align-items-center">
                    <div className="col mobile-padding-reset">
                      <StaticImage
                        className="img-fluid "
                        src="../resources/images/logos-integrations/slack.png"
                        placeholder="tracedSVG"
                        alt="slack integration"
                      />
                    </div>
                    <div className="col mobile-padding-reset">Slack</div>
                  </div>
                </div>
                <div className="col-md-4 offset-sm-0 offset-2">
                  <div className="logo-box logo-box-width d-flex align-items-center">
                    <div className="col mobile-padding-reset">
                      Custom plugins
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-8 mt-5">
              <h2 className="card-title paragraph-title pb-0">Serialisation</h2>
              <p className="card-text">
                Support for different serialization formats including JSON, CSV,
                AVRO, ProtoBuf, Thrift and even proprietary formats via a serde.
              </p>

              <a
                href="/usecases/apache-kafka-data-observability/"
                className=" primary-text"
              >
                Data formats <i className="red-pointer-right"></i>
              </a>

              <div className="row mt-4">
                <div className="col-md-3 offset-3 offset-sm-0">
                  <div className="logo-box"> JSON </div>
                </div>
                <div className="col-md-3 offset-3 offset-sm-0">
                  <div className="logo-box"> AVRO </div>
                </div>
                <div className="col-md-3 offset-3 offset-sm-0">
                  <div className="logo-box"> XML </div>
                </div>
                <div className="col-md-3 offset-3 offset-sm-0">
                  <div className="logo-box"> CSV </div>
                </div>
                <div className="col-md-3 offset-3 offset-sm-0">
                  <div className="logo-box"> Custom </div>
                </div>
              </div>
            </div>

            <div className="col-md-8 mt-5">
              <h2 className="card-title paragraph-title pb-0">
                Security & Identity Managements
              </h2>
              <p className="card-text">
                Connect Lenses with your corporate enterprise authentication and
                identity management solutions. Manage{' '}
                <a href="https://docs.lenses.io/4.3/tools/cli/admin/audits/">
                  audits
                </a>{' '}
                via Lenses or send them on to your SIEM
              </p>

              <a
                href="/usecases/apache-kafka-security/"
                className=" primary-text"
              >
                Kafka security <i className="red-pointer-right"></i>
              </a>

              <div className="row mt-4">
                <div className="col-md-3 offset-sm-0 offset-2">
                  <div className="logo-box"> LDAP </div>
                </div>
                <div className="col-md-3 offset-sm-0 offset-2">
                  <div className="logo-box"> Active Directory </div>
                </div>
                <div className="col-md-3 offset-sm-0 offset-2">
                  <div className="logo-box"> Kerberos </div>
                </div>
                <div className="col-md-3 offset-sm-0 offset-2">
                  <div className="logo-box"> SAML </div>
                </div>
                <div className="col-md-3 offset-sm-0 offset-2">
                  <div className="logo-box"> Custom </div>
                </div>
              </div>
            </div>

            <div className="col-md-8 mt-5">
              <h2 className="card-title paragraph-title pb-0">
                Deployment Options
              </h2>
              <p className="card-text">
                Simple deployment options to allow you to be up and running in
                minutes wherever your data infrastructure lives
              </p>
              <a href="/product/deployment/" className=" primary-text">
                Deploy Lenses <i className="red-pointer-right"></i>
              </a>

              <div className="row mt-4 ">
                <div className="col-md-3 offset-2 offset-sm-0">
                  <a
                    href="/cloud/aws-msk/"
                    id="arch-cloud-amazon"
                    className="logo-box-link"
                  >
                    <div className="logo-box d-flex">
                      <div className="col mobile-padding-reset">
                        <StaticImage
                          className="w-100"
                          src="../resources/images/logos-clouds/amazon-web-services.svg"
                          placeholder="tracedSVG"
                          alt="deploy on amazon"
                        />
                      </div>
                      <div className="col mobile-padding-reset">Amazon</div>
                    </div>
                  </a>
                </div>
                <div className="col-md-3 offset-2 offset-sm-0">
                  <a
                    href="/cloud/azure/"
                    id="arch-cloud-azure"
                    className="logo-box-link"
                  >
                    <div className="logo-box d-flex">
                      <div className="col mobile-padding-reset">
                        <StaticImage
                          className="img-fluid "
                          src="../resources/images/logos-clouds/azure.svg"
                          placeholder="tracedSVG"
                          alt="deploy on azure hdinsight"
                        />
                      </div>
                      <div className="col mobile-padding-reset">Azure</div>
                    </div>
                  </a>
                </div>
                <div className="col-md-3 offset-2 offset-sm-0">
                  <a
                    href="/cloud/google-cloud/"
                    id="arch-cloud-googlecloud"
                    className="logo-box-link"
                  >
                    <div className="logo-box d-flex">
                      <div className="col mobile-padding-reset">
                        <StaticImage
                          className="img-fluid"
                          src="../resources/images/logos-clouds/google-cloud.svg"
                          placeholder="tracedSVG"
                          alt="deploy on google cloud"
                        />
                      </div>
                      <div className="col mobile-padding-reset">Google CP</div>
                    </div>
                  </a>
                </div>
                <div className="col-md-3 offset-2 offset-sm-0">
                  <a
                    href="/cloud/aiven/"
                    id="arch-cloud-aiven"
                    className="logo-box-link"
                  >
                    <div className="logo-box d-flex">
                      <div className="col mobile-padding-reset">
                        <StaticImage
                          className="img-fluid"
                          src="../resources/images/cloud/aiven_crab.svg"
                          placeholder="tracedSVG"
                          alt="deploy on Aiven"
                        />
                      </div>
                      <div className="col mobile-padding-reset">AIVEN</div>
                    </div>
                  </a>
                </div>

                <div className="col-md-3 offset-2 offset-sm-0">
                  <div className="logo-box d-flex">
                    <div className="col mobile-padding-reset">
                      <StaticImage
                        className="img-fluid opct-6 customer-gray"
                        src="../resources/images/icons/linux.png"
                        placeholder="tracedSVG"
                        alt="deploy on linux vm"
                      />
                    </div>
                    <div className="col mobile-padding-reset">Linux VM</div>
                  </div>
                </div>

                <div className="col-md-3 offset-2 offset-sm-0">
                  <a
                    href="https://docs.lenses.io/4.3/installation/docker/"
                    id="downloads-docker"
                    className="logo-box-link"
                  >
                    <div className="logo-box d-flex">
                      <div className="col mobile-padding-reset">
                        <StaticImage
                          className="w-100"
                          src="../resources/images/icons/docker.png"
                          placeholder="tracedSVG"
                          alt="deploy on docker"
                        />
                      </div>
                      <div className="col mobile-padding-reset">Docker</div>
                    </div>
                  </a>
                </div>

                <div className="col-md-3 offset-2 offset-sm-0">
                  <a
                    href="https://docs.lenses.io/4.3/installation/kubernetes/"
                    id="downloads-helm"
                    className="logo-box-link"
                  >
                    <div className="logo-box d-flex">
                      <div className="col mobile-padding-reset">
                        <StaticImage
                          className="img-fluid "
                          src="../resources/images/icons/helm.png"
                          placeholder="tracedSVG"
                          alt="deploy on helm"
                        />
                      </div>
                      <div className="col mobile-padding-reset">Helm</div>
                    </div>
                  </a>
                </div>

                <div className="col-md-3 offset-2 offset-sm-0">
                  <div className="logo-box d-flex">
                    <div className="col">Managed Cloud</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" pb-5">
        <div className="container-1">
          <div className="row mt-5 mb-5 ">
            <div className="col-md-6 mt-5 col-12 mobile-text-center">
              <StaticImage
                className="architecture-bottom-logo"
                placeholder="tracedSVG"
                src="../resources/images/logos-kafka/kafka-logo-icon.png"
                alt="apache kafka icon"
              />
              <br />
              <h2 className="paragraph-title ">Lenses for Kafka</h2>
              <a href="/usecases/" className=" primary-text">
                Use cases <i className="red-pointer-right"></i>
              </a>
            </div>

            <div className="col-md-6 mt-5 col-12 mobile-text-center">
              <StaticImage
                className="architecture-bottom-logo"
                src="../resources/images/partners/redis-logo-icon.png"
                placeholder="tracedSVG"
                alt="redis icon"
              />
              <br />
              <h2 className="paragraph-title">Lenses for Redis</h2>
              <a href="/kafka-connectors/kafka-to-redis/" className=" primary-text">
                Redis connector <i className="red-pointer-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <AboveFooterSection
        text={'Ready to get started with Lenses?'}
        ctaText={"Let's go"}
        ctaLink="/start/"
      />
    </Layout>
  )
}
export default architecture
